/* ------------------------------------------------------------------------------
@name: Customer
@description: Customer
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL, WHITESPACE } from "../variables";

// --- utilities
import { Session, Form, HttpRequest, SweetAlert } from "utilities";

// --- core
import { DataTable } from "core";

const userData = JSON.parse(Session.get("userData"));

// Form ElementSelector
const ElementSelector = [
	{
		id: "address_id",
	},
	{
		id: "customer_id",
	},
	{
		id: "name",
		validation: {
			required: true,
		},
	},
	{
		id: "type",
		validation: {
			required: true,
		},
	},
	{
		id: "address",
		validation: {
			required: true,
		},
	},
];
const ElementEvents = ["input", "blur"];

const Address = (() => {
	// Handle Run DataTable
	const handleRunDataTable = () => {
		const _url = window.location.href;
		const _arrUrl = _url.split("/");
		const _customerId = _arrUrl.slice(-1).pop();

		const dataSetting = {
			selector: "js-data-address",
			url: API_URL.ADDRESS,
			method: "GET",
			token: userData.token,
			data: {
				customerId: _customerId,
			},
		};

		const columnSetting = [
			{ targets: [4], className: "text-center" },
			{ targets: 0, orderable: false },
			// { name: "nama", targets: 1 },
			// { name: "alamat", targets: 2 },
		];

		const filterSetting = [
			{
				id: "search",
				event: "keyup",
			},
		];

		DataTable.server(dataSetting, columnSetting, filterSetting);
	};

	// Handle Run Validation
	const handleRunValidation = () => {
		Form.validation(ElementEvents, ElementSelector);
	};

	// Handle Click Validation
	const handleClickValidation = () => {
		$('button[type="submit"]').on("click", (e) => {
			$.each(ElementSelector, (i, v) => {
				$("#" + v.id).blur();
			});

			if ($(".error").length === 0) {
				handleSaveData();
			}
			e.preventDefault();
		});
	};

	// handleSaveData
	const handleSaveData = () => {
		const dataCollection = Form.dataCollection(ElementSelector);

		const _url = window.location.href;
		const _arrUrl = _url.split("/");
		const _customerId = _arrUrl.slice(-1).pop();

		// Merge dataCollection with _customerId
		const mergedData = {
			customer_id: _customerId, // Add customerId as a new property
			...dataCollection, // Spread the properties of dataCollection
		};

		const endpoint = API_URL.ADDRESS;
		let _method = "POST";

		if ($("#address_id").val().length !== 0) {
			_method = "PUT";
		}

		const requestData = {
			url: endpoint,
			method: _method,
			data: mergedData,
			elementSelector: ElementSelector,
		};

		Form.sendData(requestData);
	};

	// handleEditData
	const handleEditData = () => {
		$("body").on("click", ".js-edit-data", (e) => {
			const _this = $(e.currentTarget);
			const addressId = _this.attr("data-id");

			const data = {
				url: API_URL.ADDRESS,
				data: { address_id: addressId },
				elementSelector: ElementSelector,
			};

			Form.getData(data);
		});
	};

	// handleDeleteData
	const handleDeleteData = () => {
		$("body").on("click", ".js-delete-data", (e) => {
			const _this = $(e.currentTarget);
			const addressId = _this.attr("data-id");
			const data = {
				url: API_URL.ADDRESS,
				method: "DELETE",
				data: { address_id: addressId },
			};

			Form.deleteData(data);
		});
	};

	// handleEmptyInput
	const handleEmptyInput = () => {
		$('button[data-dismiss="modal"]').on("click", (e) => {
			Form.emptyData(ElementSelector);
		});
	};

	// init
	const init = () => {
		if ($(".js-data-address").length) {
			handleRunDataTable();
			handleRunValidation();
			handleClickValidation();
			handleEditData();
			handleDeleteData();
			handleEmptyInput();
		}
	};

	return {
		init,
	};
})();

export default Address;
