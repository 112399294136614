/* ------------------------------------------------------------------------------
@name: API_URL
@description: API_URL
--------------------------------------------------------------------------------- */

import { BASE_URL } from "./BASE_URL";

export const API_URL = {
  LOGIN: `${BASE_URL}auth/login`,
  GENERATE_TOKEN: `${BASE_URL}generate-token`,
  USERS: `${BASE_URL}users`,
  USER_LOGS: `${BASE_URL}users/log`,
  META: `${BASE_URL}meta`,
  PROFILE: `${BASE_URL}profile`,
  CUSTOMERS: `${BASE_URL}customers`,
  ADDRESS: `${BASE_URL}customers/address`,
  PRODUCTS: `${BASE_URL}products`,
  ORDERS: `${BASE_URL}orders`,
  PAYMENT: `${BASE_URL}payment`,
  COURIER: `${BASE_URL}courier`,
};
