/* ------------------------------------------------------------------------------
@name: Courier
@description: Courier
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL, WHITESPACE } from "../variables";

// --- utilities
import { Session, Form, HttpRequest, SweetAlert } from "utilities";

// --- core
import { DataTable } from "core";

const userData = JSON.parse(Session.get("userData"));

// Form ElementSelector
const ElementSelector = [
  {
    id: "courier_id",
  },
  {
    id: "name",
    validation: {
      required: true,
    },
  },
  {
    id: "status",
    type: "checkbox",
  },
];
const ElementEvents = ["input", "blur"];

const Courier = (() => {
  // Handle Run DataTable
  const handleRunDataTable = () => {
    const dataSetting = {
      selector: "js-data-courier",
      url: API_URL.COURIER,
      method: "GET",
      token: userData.token,
    };

    const columnSetting = [
      { targets: [3], className: "text-center" },
      { targets: 0, orderable: false },
      // { name: "nama", targets: 1 },
      // { name: "alamat", targets: 2 },
    ];

    const filterSetting = [
      {
        id: "search",
        event: "keyup",
      },
    ];

    DataTable.server(dataSetting, columnSetting, filterSetting);
  };

  // Handle Run Validation
  const handleRunValidation = () => {
    Form.validation(ElementEvents, ElementSelector);
  };

  // Handle Click Validation
  const handleClickValidation = () => {
    $('button[type="submit"]').on("click", (e) => {
      $.each(ElementSelector, (i, v) => {
        $("#" + v.id).blur();
      });

      if ($(".error").length === 0) {
        handleSaveData();
      }
      e.preventDefault();
    });
  };

  // handleSaveData
  const handleSaveData = () => {
    const dataCollection = Form.dataCollection(ElementSelector);

    const endpoint = API_URL.COURIER;
    let _method = "POST";

    if ($("#courier_id").val().length !== 0) {
      _method = "PUT";
    }

    const requestData = {
      url: endpoint,
      method: _method,
      data: dataCollection,
      elementSelector: ElementSelector,
    };

    Form.sendData(requestData);
  };

  // handleEditData
  const handleEditData = () => {
    $("body").on("click", ".js-edit-data", (e) => {
      const _this = $(e.currentTarget);
      const addressId = _this.attr("data-id");

      const data = {
        url: API_URL.COURIER,
        data: { courier_id: addressId },
        elementSelector: ElementSelector,
      };

      Form.getData(data);
    });
  };

  // handleDeleteData
  const handleDeleteData = () => {
    $("body").on("click", ".js-delete-data", (e) => {
      const _this = $(e.currentTarget);
      const addressId = _this.attr("data-id");
      const data = {
        url: API_URL.COURIER,
        method: "DELETE",
        data: { courier_id: addressId },
      };

      Form.deleteData(data);
    });
  };

  // handleEmptyInput
  const handleEmptyInput = () => {
    $('button[data-dismiss="modal"]').on("click", (e) => {
      Form.emptyData(ElementSelector);
    });
  };

  // init
  const init = () => {
    if ($(".js-data-courier").length) {
      handleRunDataTable();
      handleRunValidation();
      handleClickValidation();
      handleEditData();
      handleDeleteData();
      handleEmptyInput();
    }
  };

  return {
    init,
  };
})();

export default Courier;
