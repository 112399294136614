/* ------------------------------------------------------------------------------
@name: Customer
@description: Customer
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL, WHITESPACE } from "../variables";

// --- utilities
import { Session, Form, HttpRequest, SweetAlert } from "utilities";

// --- core
import { DataTable } from "core";

const userData = JSON.parse(Session.get("userData"));

// Form ElementSelector
const ElementSelector = [
  {
    id: "customer_id",
  },
  {
    id: "photo",
    type: "file",
  },
  {
    id: "name",
    validation: {
      required: true,
    },
  },
  {
    id: "password",
  },
  {
    id: "type",
    validation: {
      selectRequired: true,
      selectOption: true,
    },
  },
  {
    id: "email",
    validation: {
      required: true,
    },
  },
  {
    id: "phone",
    phone: true,
    validation: {
      required: true,
    },
  },
  {
    id: "address",
  },
  {
    id: "status",
    type: "checkbox",
  },
];
const ElementEvents = ["input", "blur"];

const Customer = (() => {
  // Handle Run DataTable
  const handleRunDataTable = () => {
    const dataSetting = {
      selector: "js-data-customer",
      url: API_URL.CUSTOMERS,
      method: "GET",
      token: userData.token,
    };

    const columnSetting = [
      { targets: [5, 6], className: "text-center" },
      { targets: 0, orderable: false },
      { name: "idpelanggan", targets: 2 },
      { name: "nama", targets: 3 },
      { name: "alamat", targets: 3 },
    ];

    const filterSetting = [
      {
        id: "dateRange",
        event: "change",
      },
      {
        id: "status",
        event: "change",
      },
      {
        id: "search",
        event: "keyup",
      },
    ];

    DataTable.server(dataSetting, columnSetting, filterSetting);
  };

  // Handle Run Validation
  const handleRunValidation = () => {
    Form.validation(ElementEvents, ElementSelector);
  };

  // Handle Click Validation
  const handleClickValidation = () => {
    $('button[type="submit"]').on("click", (e) => {
      $.each(ElementSelector, (i, v) => {
        $("#" + v.id).blur();
      });

      if ($(".error").length === 0) {
        handleSaveData();
      }
      e.preventDefault();
    });
  };

  // handleSaveData
  const handleSaveData = () => {
    const dataCollection = Form.dataCollection(ElementSelector, "multipart");
    const endpoint = API_URL.CUSTOMERS;

    const requestData = {
      url: endpoint,
      method: "POST",
      data: dataCollection,
      elementSelector: ElementSelector,
    };

    Form.sendData(requestData, "multipart");
  };

  // handleEditData
  const handleEditData = () => {
    $("body").on("click", ".js-edit-data", (e) => {
      const _this = $(e.currentTarget);
      const customerId = _this.attr("data-id");

      const data = {
        url: API_URL.CUSTOMERS,
        data: { customer_id: customerId },
        elementSelector: ElementSelector,
      };

      Form.getData(data);
    });
  };

  // handleDeleteData
  const handleDeleteData = () => {
    $("body").on("click", ".js-delete-data", (e) => {
      const _this = $(e.currentTarget);
      const customerId = _this.attr("data-id");
      const data = {
        url: API_URL.CUSTOMERS,
        method: "DELETE",
        data: { customer_id: customerId },
      };

      Form.deleteData(data);
    });
  };

  // handleEmptyInput
  const handleEmptyInput = () => {
    $('button[data-dismiss="modal"]').on("click", (e) => {
      Form.emptyData(ElementSelector);
    });
  };

  // init
  const init = () => {
    if ($(".js-data-customer").length) {
      handleRunDataTable();
      handleRunValidation();
      handleClickValidation();
      handleEditData();
      handleDeleteData();
      handleEmptyInput();
    }
  };

  return {
    init,
  };
})();

export default Customer;
