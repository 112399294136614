/* ------------------------------------------------------------------------------
@name: HttpRequest
@description: HttpRequest Activate
--------------------------------------------------------------------------------- */
// --- variables
import { API_URL } from "../variables";

// --- utilities
import { Session, SweetAlert } from "utilities";

const userData = JSON.parse(Session.get("userData"));

const HttpRequest = (() => {
	// handleRequestMultipartData
	const handleRequestMultipartData = async (
		data,
		token,
		beforeSend = false
	) => {
		try {
			const headers = {};

			// Add the Authorization header if token is provided
			if (token) {
				headers.Authorization = `Bearer ${token}`;
			}
			const response = await $.ajax({
				url: data.url,
				method: data.method,
				dataType: "JSON",
				cache: false,
				contentType: false,
				processData: false,
				data: data.data,
				headers: headers, // Use dynamic headers
				beforeSend: () => {
					if (beforeSend && typeof beforeSend === "function") {
						beforeSend(); // Execute callback if provided
					}
				},
			});

			return response;
		} catch (error) {
			// Check if the error is due to token expiration (status code 401)
			if (error.status === 401) {
				try {
					// Call the generate API to get a new token
					const newToken = await handleGenerateNewToken();

					if (newToken) {
						// Retry the request with the new token
						return await handleRequestData(data, newToken, beforeSend);
					} else {
						// If token generation fails, prompt the user to log in again
						SweetAlert.config("Session experied. Please Login again", "error");
						return error;
					}
				} catch (tokenError) {
					// Handle failure in token generation, prompt user to log in again
					SweetAlert.config(
						"Could not refresh token. Please log in again",
						"error"
					);
					return null;
				}
			}
			SweetAlert.config(
				error.responseJSON?.message || "Error during the request",
				"error"
			);

			return error.responseJSON;
		}
	};

	// handleRequest
	const handleRequestData = async (data, token, beforeSend = false) => {
		try {
			const headers = {};

			// Add the Authorization header if a token is provided
			if (token) {
				headers.Authorization = `Bearer ${token}`;
			}

			const response = await $.ajax({
				url: data.url,
				method: data.method,
				dataType: "JSON",
				data: data.data,
				headers: headers,
				beforeSend: () => {
					if (beforeSend && typeof beforeSend === "function") {
						beforeSend(); // Execute callback if provided
					}
				},
			});

			return response;
		} catch (error) {
			// Check if the error is due to token expiration (status code 401)
			if (error.status === 401) {
				try {
					// Generate a new token
					const newToken = await handleGenerateNewToken();

					if (newToken) {
						// Retry the request with the new token
						return await handleRequestData(data, newToken, beforeSend);
					} else {
						// If token generation fails, prompt the user to log in again
						SweetAlert.config("Session expired. Please log in again.", "error");
						return null;
					}
				} catch (tokenError) {
					// Handle failure in token generation, prompt user to log in again
					SweetAlert.config(
						"Could not refresh token. Please log in again.",
						"error"
					);
					return error;
				}
			}

			// For other errors, show a generic error message
			SweetAlert.config(
				error.responseJSON?.message || "Error during the request",
				"error"
			);

			return error.responseJSON;
		}
	};

	// Function to generate a new token
	const handleGenerateNewToken = async () => {
		try {
			const response = await $.ajax({
				url: API_URL.GENERATE_TOKEN, // Replace with your actual generate token API endpoint
				method: "POST",
				dataType: "JSON",
				data: JSON.stringify({ username: userData.username }), // Send username as JSON
				headers: {
					"Content-Type": "application/json",
				},
			});
			// Update the token
			userData.token = response.token;
			// Set the updated user data back to session
			Session.set("userData", JSON.stringify(userData));

			return response.token; // Assuming the API returns the new token in the response
		} catch (error) {
			console.error("Failed to generate a new token:", error);
			return null;
		}
	};

	return {
		generateNewToken: handleGenerateNewToken,
		multipartData: handleRequestMultipartData,
		data: handleRequestData,
	};
})();

export default HttpRequest;
