/* ------------------------------------------------------------------------------
@name: Core Index
@description: Indexing all core
--------------------------------------------------------------------------------- */

import DataTable from "./DataTable";
import SweetAlert from "./SweetAlert";
import Dropify from "./Dropify";
import TemporaryAlert from "./TemporaryAlert";
import BackButton from "./BackButton";
import DatePickerInput from "./DatePickerInput";
import SelectInput from "./SelectInput";
import EditableTables from "./EditableTables";
import ChangeFormatNumber from "./ChangeFormatNumber";
import Password from "./Password";
import Dropdown from "./Dropdown";
import CheckBox from "./CheckBox";
import Modal from "./Modal";

export {
	DataTable,
	SweetAlert,
	Dropify,
	TemporaryAlert,
	BackButton,
	DatePickerInput,
	SelectInput,
	EditableTables,
	ChangeFormatNumber,
	Password,
	Dropdown,
	CheckBox,
	Modal,
};
