/* ------------------------------------------------------------------------------
@name: Components Index
@description: Indexing all components
--------------------------------------------------------------------------------- */

import Header from "./Header.js";
import Login from "./Login.js";
import Users from "./Users.js";
import UserLogs from "./UserLogs.js";
import Meta from "./Meta.js";
import Profile from "./Profile.js";
import Product from "./Product.js";
import Customer from "./Customer.js";
import Address from "./Address.js";
import Orders from "./Orders.js";
import PaymentAccount from "./PaymentAccount.js";
import Courier from "./Courier.js";

export {
  Header,
  Login,
  Users,
  Meta,
  Profile,
  UserLogs,
  Product,
  Customer,
  Address,
  Orders,
  PaymentAccount,
  Courier,
};
