/* ------------------------------------------------------------------------------
@name: DataTable
--------------------------------------------------------------------------------- */

// --- utilities
import { HttpRequest, Form, SweetAlert, FormatDate } from "utilities";

const DataTable = (() => {
	const handleDataTable = () => {
		// data table defautl
		$(".js-datatable").DataTable({
			responsive: true,
			autoWidth: false,
			stateSave: true,
		});
	};

	const handleRunDataTableServer = (
		dataSetting, // Object containing settings for data URL, HTTP method, etc.
		columnSetting = [], // Array for defining columns and their properties
		filterSetting = [], // Array to specify filtering options and events
		columnVisibleSetting = null, // Object for column visibility settings
		sortSetting = { columnName: "", order: "desc" } // Default sorting settings
	) => {
		// Helper function to get the column index by column name
		const getColumnIndexByName = (name) => {
			const column = columnSetting.find((col) => col.name === name);
			return column ? column.targets : -1;
		};

		// Configuration for DataTables initialization
		const tableSetting = {
			info: false, // Hide the info text
			processing: true, // Show processing indicator
			serverSide: true, // Enable server-side processing
			responsive: true, // Make table responsive
			autoWidth: false, // Disable automatic column width
			stateSave: true, // Save the table state (pagination, etc.)
			dom: '<"float-right"f>rt<"row"<"col-sm-4"l><"col-sm-4"i><"col-sm-4"p>>', // Define the layout structure
			order: sortSetting.columnName
				? [[getColumnIndexByName(sortSetting.columnName), sortSetting.order]]
				: [], // Set initial sorting

			ajax: {
				url: dataSetting.url, // Use URL from dataSetting
				type: dataSetting.method, // HTTP method from dataSetting
				data: function (data) {
					// Convert column index to column name for ordering
					if (data.order && data.order[0]) {
						const orderColumnIndex = data.order[0].column;
						const columnName = columnSetting.find(
							(col) => col.targets === orderColumnIndex
						)?.name;
						data.order[0].column = columnName || orderColumnIndex;
					}

					// Merge all properties from dataSetting.data into data
					if (dataSetting.data) {
						Object.assign(data, dataSetting.data);
					}

					data.token = dataSetting.token; // Add token for authentication

					const selector = $("." + dataSetting.selector)
						.parents("body")
						.find("#dateRange");

					if (selector.length) {
						const _date = $("#dateRange").val();
						const _startDate = FormatDate.range(_date).startDate;
						const _endDate = FormatDate.range(_date).endDate;

						data.startDate = _startDate;
						data.endDate = _endDate;
					}

					return data;
				},
				beforeSend: (xhr) => setAuthorizationHeader(xhr, dataSetting.token), // Set auth header
				error: (xhr, status, error) =>
					handleAjaxError(xhr, error, dataSetting, table), // Handle AJAX errors
			},

			columnDefs: columnSetting, // Set column definitions
			language: { processing: '<div class="table-loader"></div>' }, // Customize the loading indicator
		};

		// Initialize DataTable
		const table = $("." + dataSetting.selector).DataTable(tableSetting);

		// Apply initial sorting if specified
		if (sortSetting.columnName) {
			const initialColumnIndex = getColumnIndexByName(sortSetting.columnName);
			if (initialColumnIndex >= 0) {
				table.order([initialColumnIndex, sortSetting.order || "asc"]).draw();
			}
		}

		// Apply filters and column visibility settings
		applyFilters(filterSetting, table);
		applyColumnVisibility(columnVisibleSetting, table);

		// Set to store selected row IDs
		let selectedRows = new Set();

		// HTML for the delete button
		const deleteButtonHtml = `<button type="button" class="btn btn-danger waves-effect w-md waves-light" id="deleteBatch"><i class="mdi mdi-trash-can-outline"></i> Delete Batch</button>`;

		// Function to update visibility of delete button based on selected rows
		const updateDeleteButton = () => {
			selectedRows.size
				? !$("#deleteBatch").length &&
				  $(".form-inline").prepend(deleteButtonHtml)
				: $("#deleteBatch").remove();
		};

		// Handle row selection with checkboxes
		handleRowSelection(
			table,
			dataSetting.selector,
			selectedRows,
			updateDeleteButton
		);

		// Bind click event for batch deletion
		$("body").on("click", "#deleteBatch", () =>
			handleBatchDelete(dataSetting, selectedRows)
		);

		// --- Helper Functions ---

		// Set Authorization header
		function setAuthorizationHeader(xhr, token) {
			xhr.setRequestHeader("Authorization", "Bearer " + token);
		}

		// Handle AJAX errors, including token expiration
		function handleAjaxError(xhr, error, dataSetting, table) {
			if (xhr.status === 401) {
				// Handle expired token case
				HttpRequest.generateNewToken()
					.then((newToken) => {
						if (newToken) {
							dataSetting.token = newToken;
							table.ajax.reload(null, false); // Reload table with new token
						} else {
							SweetAlert.config(
								"Session expired. Please log in again.",
								"error"
							);
						}
					})
					.catch(() => {
						SweetAlert.config(
							"Could not refresh token. Please log in again.",
							"error"
						);
					});
			} else {
				console.error(`Error: ${xhr.status} - ${error}`);
				SweetAlert.config(error, "error");
			}
		}

		// Apply filters to the DataTable based on filter settings
		function applyFilters(filterSetting, table) {
			filterSetting.forEach((filter) => {
				$("#" + filter.id).on(filter.event, (e) => {
					const value = $(e.currentTarget).val();
					filter.event === "change" ? table.draw() : table.search(value).draw(); // Apply search or filter
				});
			});
		}

		// Adjust column visibility based on settings
		function applyColumnVisibility(columnVisibleSetting, table) {
			if (columnVisibleSetting) {
				table
					.columns(columnVisibleSetting.target)
					.visible(columnVisibleSetting.visible);
			}
		}

		// Handle selection of individual and all rows
		function handleRowSelection(
			table,
			selector,
			selectedRows,
			updateDeleteButton
		) {
			// Select/Deselect all rows
			$("#selectAll").on("click", function () {
				const rows = table.rows({ search: "applied" }).nodes();
				const isChecked = this.checked;
				$('input[type="checkbox"]', rows).prop("checked", isChecked);

				selectedRows.clear(); // Clear selected rows
				if (isChecked) {
					// Add all rows to selectedRows set if "Select All" is checked
					$(rows)
						.find('input[type="checkbox"]')
						.each((i, el) => selectedRows.add($(el).val()));
				}

				updateDeleteButton(); // Update the delete button state
			});

			// Select/Deselect individual row
			$("." + selector + " tbody").on(
				"change",
				'input[type="checkbox"]',
				function () {
					const id = $(this).val();
					this.checked ? selectedRows.add(id) : selectedRows.delete(id);

					$("#selectAll").prop("checked", false); // Uncheck "Select All" if any checkbox changes
					updateDeleteButton();
				}
			);
		}

		// Handle batch deletion of selected rows
		function handleBatchDelete(dataSetting, selectedRows) {
			if (selectedRows.size === 0) return; // Exit if no rows are selected

			// selectedRows.clear();
			// $("#selectAll").prop("checked", false);
			// $("#deleteBatch").remove(); // Remove delete button
			// table.ajax.reload(null, false); // Reload table data

			const deleteData = {
				url: dataSetting.url,
				method: "DELETE",
				data: {
					[$(".js-select-all-checkbox").attr("data-delete")]:
						Array.from(selectedRows), // Convert Set to Array
				},
			};

			swal({
				title: "Apakah Anda yakin?",
				text: "Tindakan ini tidak dapat diurungkan!",
				type: "warning",
				showCancelButton: true,
				confirmButtonText: "Ya, Hapus",
				cancelButtonText: "Batal",
				confirmButtonClass: "btn btn-success",
				cancelButtonClass: "btn btn-danger m-l-10",
				buttonsStyling: false,
			}).then(
				async () => {
					try {
						// Call HttpRequest.data and wait for the response
						const response = await HttpRequest.data(
							deleteData,
							dataSetting.token
						);

						// Check if the response is successful
						if (response && response.status) {
							// Close modal, show success message, and reload DataTable
							$(".modal").modal("hide");
							SweetAlert.config("Data deleted successfully", "success");
							selectedRows.clear();
							$("#selectAll").prop("checked", false);
							$("#deleteBatch").remove(); // Remove delete button
							table.ajax.reload(null, false); // Reload table data

							return response; // Return response if needed elsewhere
						} else {
							// Handle error message if the response status is not successful
							SweetAlert.config(
								response?.message || "Error deleting data",
								"error"
							);
						}
					} catch (error) {
						// Log and display error if the request fails
						console.error("Error during delete request:", error);
						SweetAlert.config(
							"Failed to delete data. Please try again.",
							"error"
						);
					}
				},
				(dismiss) => {
					// Handle case where the user cancels the deletion
					if (dismiss === "cancel") {
						swal("Batal", "Data Anda aman :)", "error");
					}
				}
			);
		}
	};

	// -- init
	const init = () => {
		handleDataTable();
	};

	return {
		init,
		server: handleRunDataTableServer,
	};
})();

export default DataTable;
