/* ------------------------------------------------------------------------------
@name: Products
@description: Products
--------------------------------------------------------------------------------- */

// --- variables
import { API_URL, WHITESPACE } from "../variables";

// --- utilities
import { Session, Form, HttpRequest, SweetAlert } from "utilities";

// --- core
import { DataTable } from "core";

const userData = JSON.parse(Session.get("userData"));

// Form ElementSelector
const ElementSelector = [
	{
		id: "product_id",
	},
	{
		id: "photo",
		type: "file",
	},
	{
		id: "name",
	},
	{
		id: "description",
		validation: {
			required: true,
		},
	},
];
const ElementEvents = ["input", "blur"];

const Orders = (() => {
	// Handle Run DataTable
	const handleRunDataTable = () => {
		const dataSetting = {
			selector: "js-data-order",
			url: API_URL.ORDERS,
			method: "GET",
			token: userData.token,
		};

		const columnSetting = [
			{
				targets: [0, 6],
				className: "text-center",
			},
			{
				targets: 0,
				orderable: false,
			},
			{ name: "barcode", targets: 2 },
			{ name: "variant", targets: 3 },
			{ name: "jual", targets: 4 },
		];

		const filterSetting = [
			{
				id: "dateRange",
				event: "change",
			},
			{
				id: "search",
				event: "keyup",
			},
		];

		const sortSetting = {
			id: "sort",
			event: "change",
		};

		DataTable.server(dataSetting, columnSetting, filterSetting, sortSetting);
	};

	// Handle Run Validation
	const handleRunValidation = () => {
		Form.validation(ElementEvents, ElementSelector);
	};

	// Handle Click Validation
	const handleClickValidation = () => {
		$('button[type="submit"]').on("click", (e) => {
			$.each(ElementSelector, (i, v) => {
				$("#" + v.id).blur();
			});

			if ($(".error").length === 0) {
				handleSaveData();
			}
			e.preventDefault();
		});
	};

	// handleSaveData
	const handleSaveData = () => {
		const dataCollection = Form.dataCollection(ElementSelector, "multipart");
		const endpoint = API_URL.ORDERS;

		const requestData = {
			url: endpoint,
			method: "POST",
			data: dataCollection,
			elementSelector: ElementSelector,
		};

		Form.sendData(requestData, "multipart");
	};

	// handleEditData
	const handleEditData = () => {
		$("body").on("click", ".js-edit-data", (e) => {
			const _this = $(e.currentTarget);
			const productId = _this.attr("data-id");

			const data = {
				url: API_URL.ORDERS,
				data: { product_id: productId },
				elementSelector: ElementSelector,
			};

			Form.getData(data);
		});
	};

	// handleDeleteData
	const handleDeleteData = () => {
		$("body").on("click", ".js-delete-data", (e) => {
			const _this = $(e.currentTarget);
			const userId = _this.attr("data-id");

			const data = {
				url: API_URL.ORDERS,
				method: "DELETE",
				data: { user_id: userId },
			};

			Form.deleteData(data);
		});
	};

	// handleEmptyInput
	const handleEmptyInput = () => {
		$('button[data-dismiss="modal"]').on("click", (e) => {
			Form.emptyData(ElementSelector);
		});
	};

	// init
	const init = () => {
		if ($(".js-data-order").length) {
			handleRunDataTable();
			handleRunValidation();
			handleClickValidation();
			handleEditData();
			handleDeleteData();
			handleEmptyInput();
		}
	};

	return {
		init,
	};
})();

export default Orders;
